
    import __i18nConfig from '@next-translate-root/i18n'
    import __appWithI18n from 'next-translate/appWithI18n'
    import { createGlobalStyle } from "styled-components";
import { ThemeProvider } from "@sablier/v2-themes";
import { Analytics } from "@vercel/analytics/react";
import "cross-fetch";
import { AppProps } from "next/app";
import { ParallaxProvider } from "react-scroll-parallax";
import type { ThemeType } from "@sablier/v2-themes";
import type { PropsWithChildren } from "react";
const GlobalStyle = createGlobalStyle<{
    theme: ThemeType;
}> `
  html, body{
    background: ${(props) => props.theme.colors.dark000};
  }
`;
function Wrapper({ children }: PropsWithChildren<unknown>) {
    return (<ThemeProvider>
      <GlobalStyle />
      <ParallaxProvider>{children}</ParallaxProvider>
    </ThemeProvider>);
}
function App({ Component }: AppProps) {
    return (<Wrapper>
      <Component />
      <Analytics debug={false}/>
    </Wrapper>);
}
const __Next_Translate__Page__191e6a89d36__ = App;

    export default __appWithI18n(__Next_Translate__Page__191e6a89d36__, {
      ...__i18nConfig,
      isLoader: true,
      skipInitialProps: true,
      
    });
  